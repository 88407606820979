<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Segment Form
    </template>
    <template slot="body">
      <form-text
        v-model="form.name"
        icon="description"
        label="Name"
      />

      <md-button
        v-if="!selectingAgent"
        class="md-primary md-simple"
        @click="selectingAgent = true"
      >
        <md-icon>add</md-icon>
        Add Agent
      </md-button>

      <div
        v-if="selectingAgent"
        class="d-flex"
      >
        <AgentsSelect
          class="flex-grow-1"
          @change="onSelAgent"
        />
        <md-button
          class="ml-4 md-success md-just-icon"
          @click="addAgent"
        >
          <md-icon>check</md-icon>
        </md-button>
      </div>

      <template v-if="form.agents.length">
        <h4>Agents</h4>
        <ul>
          <li
            v-for="(a,index) of form.agents"
            :key="index"
            class="d-flex justify-content-center align-items-center"
          >
            {{ a.name }}

            <md-button
              class="md-sm ml-auto md-simple md-danger"
              @click="removeAgent(index)"
            >
              <md-icon>close</md-icon>
            </md-button>
          </li>
        </ul>
      </template>
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        @click="saveItem"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
} from '@/components/Inputs';
import {
  AgentsSelect,
} from '@/components/Inputs/selects';

export default {
  components: {
    FormText,
    AgentsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        agents: [],
        name: null,
      },
      selectingAgent: false,
      selAgent: null,
    };
  },
  mounted() {
    this.form = { ...this.item };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    addAgent() {
      this.form.agents.push(this.selAgent);
    },
    onSelAgent(e) {
      this.selAgent = e;
    },
    removeAgent(index) {
      this.form.agents.splice(index, 1);
    },
    saveItem() {
      if (this.form.segment_id) {
        this.request(`crm/segments/${this.form.segment_id}`, 'put', this.form, () => {
          this.fireSuccess('Segment Updated Succesfully');
          this.$emit('save');
          this.close();
        });
      } else {
        this.request('crm/segments', 'post', this.form, () => {
          this.fireSuccess('Segment Created Succesfully');
          this.$emit('save');
          this.close();
        });
      }
    },
  },
};
</script>
<style></style>
